<template>
  <div class="col s12 companies">
    <p
      v-if="type === 'company_list' && search_size === 0"
      v-html="vt('blankslate')"
    />
    <div
      v-if="type === 'company_list' && search_size > 0"
      class="table-wrapper"
    >
      <table>
        <thead>
          <tr>
            <th class="checkbox center-align">
              <label>
                <input
                  class="filled-in"
                  type="checkbox"
                  :checked="selection.size !== 0 && selection.size === companies.length"
                  @input="toggleCompanySelectionAll"
                >
                <span />
              </label>
            </th>
            <th
              v-if="selection.size !== 0"
              colspan="5"
            >
              <modal
                v-if="type === 'company_list'"
                id="delete-selection"
                :button_classes="['btn-outline', 'with-icon', 'primary'].concat(selection.size == 0 ? ['disabled'] : [])"
                :button_name="vt('delete_companies')"
                :button_with_icon="true"
                :modal_name="vt('delete_selection_title')"
                :icon_name="'delete'"
                :close_button_name="vt('delete_selection_close')"
              >
                <p class="p4">
                  {{ vt('delete_selection_text') }}
                </p>
                <button
                  class="btn modal-close"
                  @click="deleteCompanySelection(true)"
                >
                  {{ vt('delete_selection_delete') }}
                </button>
              </modal>
            </th>
            <th
              v-if="selection.size === 0"
              :class="{ active: sort.indexOf('company') === 0 }"
            >
              <a :href="company_list_base_path + '/' + company_list_id + '/companies?page=' + page + '&sort=' + newSortDirection('company')">
                <i
                  v-if="sort.indexOf('company') === 0"
                  class="material-icons vertical-align-middle"
                  :data-icon="sortIconDirection('company')"
                />
                {{ vt('company') }}
              </a>
            </th>
            <th
              v-if="selection.size === 0"
              :class="{ active: sort.indexOf('zip') === 0 }"
            >
              <a :href="company_list_base_path + '/' + company_list_id + '/companies?page=' + page + '&sort=' + newSortDirection('zip')">
                <i
                  v-if="sort.indexOf('zip') === 0"
                  class="material-icons vertical-align-middle"
                  :data-icon="sortIconDirection('zip')"
                />
                {{ vt('zip_and_city') }}
              </a>
            </th>
            <th
              v-if="selection.size === 0"
              :class="{ active: sort.indexOf('industry') === 0 }"
            >
              <a :href="company_list_base_path + '/' + company_list_id + '/companies?page=' + page + '&sort=' + newSortDirection('industry')">
                <i
                  v-if="sort.indexOf('industry') === 0"
                  class="material-icons vertical-align-middle"
                  :data-icon="sortIconDirection('industry')"
                />
                {{ vt('industry') }}
              </a>
            </th>
            <th
              v-if="selection.size === 0"
              :class="{ active: sort.indexOf('revenue') === 0 }"
            >
              <a :href="company_list_base_path + '/' + company_list_id + '/companies?page=' + page + '&sort=' + newSortDirection('revenue')">
                <i
                  v-if="sort.indexOf('revenue') === 0"
                  class="material-icons vertical-align-middle"
                  :data-icon="sortIconDirection('revenue')"
                />
                {{ vt('revenue') }}
              </a>
            </th>
            <th
              v-if="selection.size === 0"
              :class="{ active: sort.indexOf('employees') === 0 }"
            >
              <a :href="company_list_base_path + '/' + company_list_id + '/companies?page=' + page + '&sort=' + newSortDirection('employees')">
                <i
                  v-if="sort.indexOf('employees') === 0"
                  class="material-icons vertical-align-middle"
                  :data-icon="sortIconDirection('employees')"
                />
                {{ vt('employees') }}
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="company in companies"
            :key="company.implisense_id"
            class="collection-item"
            @click="toggleCompanySelection(company.implisense_id)"
          >
            <td class="center-align">
              <label>
                <input
                  class="filled-in"
                  type="checkbox"
                  :checked="selection.has(company.implisense_id)"
                  @click="toggleCompanySelection(company.implisense_id)"
                ><span />
              </label>
            </td>
            <td :title="company.name">
              <a :href="company.path">
                {{ company.name }}</a>
            </td>
            <td>
              <a :href="company.zip_search_path">
                {{ company.zip }}
              </a>
              {{ company.city }}
            </td>
            <td :title="company.industry_name">
              <a :href="company.industry_search_path">
                {{ company.industry_nace }}
              </a>
            </td>
            <td>
              <a :href="company.path + '#financials'">
                {{ company.revenue }}
              </a>
            </td>
            <td>{{ company.employee_count }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="type === 'company_list' && search_size > 0">
      <span class="pagination-progress">{{ vt('progress') }}</span>
      <ul class="pagination">
        <li
          v-for="p in Math.ceil(1.0 * search_size / page_size)"
          :key="p"
        >
          <a
            v-if="p != page"
            :href="company_list_base_path + '/' + company_list_id + '/companies?page=' + p + '&sort=' + sort"
          >
            {{ p }}
          </a>
          <span v-if="p == page">
            {{ p }}
          </span>
        </li>
      </ul>
    </div>
    <ul
      v-if="type === 'search'"
      class="collection"
    >
      <li class="collection-item">
        <div class="col s12">
          <h4 class="truncate sum-results">
            {{ vt('title') }}
          </h4>
          <p
            v-if="search_size === 0"
            v-html="vt('blankslate')"
          />
        </div>
      </li>
      <li
        v-for="company in companies"
        :key="company.implisense_id"
        class="collection-item"
      >
        <div
          :id="`search-company-${company.implisense_id}`"
          ref="company"
          :itemid="company.implisense_id"
          class="company"
        >
          <span
            v-if="company.favored"
            class="company-list-interactor"
            :data-base-path="company_list_base_path"
            :data-company-list-id="company.company_list_id"
            :data-implisense-id="company.implisense_id"
          >
            <i
              class="material-icons"
              data-icon="bookmark"
            />
            <span
              class="label"
              data-toggled-label=""
              data-current-label=""
            />
          </span>
          <span
            v-else
            class="company-list-interactor"
            :data-base-path="company_list_base_path"
            :data-company-list-id="company.company_list_id"
            :data-implisense-id="company.implisense_id"
          >
            <i
              class="material-icons"
              data-icon="bookmark_border"
            />
            <span
              class="label"
              data-toggled-label=""
              data-current-label=""
            />
          </span>
          <h3 class="truncate">
            <a
              :href="company.path"
            >
              {{ company.name }}
            </a>
          </h3>
          <div class="row">
            <div
              class="col s12"
              :class="company.snippet ? 'l4' : 'l6'"
            >
              <p
                v-if="company.city"
                class="p5"
              >
                <i
                  class="material-icons left"
                  data-icon="location_city"
                />
                <span
                  class="data truncate"
                  :title="company.city"
                >
                  {{ company.city }}
                </span>
              </p>
              <p
                v-if="company.industry"
                class="p5"
              >
                <i
                  class="material-icons left"
                  data-icon="work"
                />
                <span
                  class="data phone truncate"
                  :title="company.industry"
                >
                  {{ company.industry }}
                </span>
              </p>
            </div>
            <div
              class="col s12"
              :class="company.snippet ? 'l8' : 'l6'"
            >
              <p
                v-if="company.snippet"
                :title="company.snippet"
                class="p5 snippet"
                v-html="company.snippet"
              />
            </div>
          </div>
        </div>
      </li>
    </ul>
    <a
      class="btn btn-floating grey back-to-top scale-transition scale-out hide-on-med-and-down"
      @click="backToTop"
    >
      <i
        class="material-icons left"
        data-icon="arrow_upward"
      />
    </a>
    <div
      v-if="type === 'search' && search_size > (page + 1) * page_size && page < 9 && !loading"
      class="center-align"
    >
      <a
        id="show-more"
        class="btn"
        @click="loadMore"
      >
        <i
          class="material-icons left"
          data-icon="expand_more"
        />
        {{ vt('show_more') }}
      </a>
    </div>
    <div
      v-if="type === 'search' && search_size > (page + 1) * page_size && page >= 9"
      v-html="vt('end_of_scrolling')"
    />
    <div
      v-if="loading"
      class="div-align-center"
    >
      <div class="preloader-wrapper small active">
        <div class="spinner-layer spinner-blue-only">
          <div class="circle-clipper left">
            <div class="circle" />
          </div><div class="gap-patch">
            <div class="circle" />
          </div><div class="circle-clipper right">
            <div class="circle" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from './modal.vue'
export default {
  components: {
    Modal
  },
  props: {
    initial_page: {
      type: String,
      default: '0'
    },
    initial_sort: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    initial_companies: {
      type: String,
      default: '[]'
    },
    initial_locales: {
      type: String,
      default: '{}'
    },
    initial_filter_params: {
      type: String,
      default: '{}'
    },
    company_list_id: {
      type: String,
      default: ''
    },
    company_list_base_path: {
      type: String,
      default: ''
    },
    initial_search_size: {
      type: String,
      default: '0'
    },
    login_path: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      companies: JSON.parse(this.initial_companies),
      newCompanyList: [],
      filterParams: JSON.parse(this.initial_filter_params),
      page: JSON.parse(this.initial_page),
      loading: false,
      page_size: 50,
      sort: this.initial_sort,
      selection: new Set(),
      search_size: parseInt(this.initial_search_size)
    }
  },
  beforeMount() {
    if (this.type === 'search') {
      this.subscription = window.App.cable.subscriptions.create(
        { channel: 'SearchChannel' }, {
          received: data => {
            if (JSON.stringify(data.filters) != JSON.stringify(this.filterParams)) {
              return
            }
            let filtered = data.results.companies.filter(newCompany => {
              return !this.companies.some(company => company.implisense_id === newCompany.implisense_id)
            })
            this.newCompanyList = filtered
            this.companies = this.companies.concat(filtered)
            this.loading = false
          }
        })
    } else if (this.type === 'company_list') {
      this.subscription = window.App.cable.subscriptions.create(
        { channel: 'CompanyListChannel', company_list_id: this.company_list_id, page: this.page, sort: this.sort }, {
          received: data => {
            this.companies = data.companies
            this.page = data.page
            this.search_size = data.companies_count
            this.selection = new Set()
            let url = window.location + ''
            history.pushState({}, '', url.replace(/page=[0-9]+/, 'page=' + this.page))
          }
        })
    }
  },
  updated() {
    this.setEventListenerToCompanyElement()
  },
  beforeDestroy() {
    window.App.cable.subscriptions.remove(this.subscription)
  },
  methods: {
    vt(key) {
      let locales = JSON.parse(this.initial_locales)
      if (locales.progress && key === 'progress') {
        let from = ((this.page - 1) * this.page_size) + 1
        if (this.search_size === 0) {
          from = 0
        }
        let to = ((this.page - 1) * this.page_size) + this.page_size
        if (to > this.search_size) {
          to = this.search_size
        }

        if (this.search_size === 0) {
          locales.progress = locales.progress.zero
        } else if (this.search_size === 1) {
          locales.progress = locales.progress.one
        } else {
          locales.progress = locales.progress.other
        }
        locales.progress = locales.progress.replace('%{from}', from).replace('%{to}', to).replace('%{count}', this.search_size)
      } else if (locales.delete_companies && key === 'delete_companies') {
        if (this.selection.size === 0) {
          locales.delete_companies = locales.delete_companies.zero
        } else if (this.selection.size === 1) {
          locales.delete_companies = locales.delete_companies.one
        } else {
          locales.delete_companies = locales.delete_companies.other
        }
        locales.delete_companies = locales.delete_companies.replace('%{count}', this.selection.size)
      }
      return locales[key]
    },
    deleteCompanySelection() {
      if (this.selection.size > 0) {
        this.subscription.send({
          delete: {
            company_list_id: this.company_list_id,
            implisense_ids: Array.from(this.selection)
          }
        })
      }
    },
    toggleCompanySelection(implisense_id) {
      if (this.selection.has(implisense_id)) {
        this.selection.delete(implisense_id)
      } else {
        this.selection.add(implisense_id)
      }
      this.selection = new Set(this.selection)
    },
    toggleCompanySelectionAll() {
      if (this.search_size === 0) {
        return
      }
      if (this.selection.size === this.companies.length) {
        this.selection.clear()
      } else {
        this.selection = this.companies.map(company => company.implisense_id)
      }
      this.selection = new Set(this.selection)
    },
    newSortDirection(type) {
      if (this.sort === type + '_asc') {
        return type + '_desc'
      } else {
        return type + '_asc'
      }
    },
    sortIconDirection(type) {
      if (this.sort === type + '_desc') {
        return 'arrow_drop_down'
      } else {
        return 'arrow_drop_up'
      }
    },
    backToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    loadMore() {
      if (this.type !== 'search') {
        return
      }
      this.loading = true

      this.subscription.send({
        search: {
          locale: this.locale,
          filters: this.filterParams,
          page: ++this.page
        }
      })
    },
    setEventListenerToCompanyElement(){
      if (!this.$refs['company']) {
        return
      }
      this.$refs['company'].forEach(company => {
        if (this.newCompanyList.length > 1) {
          let found = this.newCompanyList.some(newCompany => {
            return newCompany.implisense_id === company.getAttribute('itemid')
          })
          if (found) {
            company = company.querySelector('.company-list-interactor')
            company.addEventListener('click', window.companyListInteractorEventListener)
          }
        }
      })
      this.newCompanyList = []
    }
  }
}
</script>
